// Migrated
<template>
  <div class="charter-page-layout">
    <LayoutWrapper>
      <Header />
      <CharterMobileMenu />
      <div class="inline banner size-full min-h-[200px] md:min-h-[600px] relative">
        <ResponsiveImage
          v-if="!isHotel"
          class="size-full"
          position="absolute"
          :max-height="650"
          :aspect-ratio="false"
          :align="DEFAULT_IMAGE_ALIGN"
          :skeleton="true"
          :clip="false"
          :image="bannerImage"
          :type="bannerType"
          alt="Hero image"
        />
        <ResponsiveHotelImage
          v-else
          class="size-full !absolute"
          :image="bannerImage"
          :type="bannerType"
          alt="Hero image"
        />
      </div>
      <template #solresorContent>
        <div
          ref="contentRef"
          :key="title"
        >
          <DestinationHotelNavTabs
            animated
            @on-destination-tab-change="updateDestinationActiveTab"
            @on-hotel-tab-change="updateHotelActiveTab"
          />
          <slot />
        </div>
      </template>
      <template #footer>
        <LazyFooter />
      </template>
    </LayoutWrapper>
  </div>
</template>

<script setup>
const { public: { images: imagesConfig } } = useRuntimeConfig()

const { getLocaleMessages: localeMessages } = useLocaleStore()

const DEFAULT_IMAGE_ALIGN = '50% 40%'
const { t } = useI18n()
const rootStore = useRootStore()
const charterHotelStore = useCharterHotelStore()
const charterDestinationStore = useCharterDestinationStore()
const contentRef = ref()
const title = computed(() => charterDestinationStore.charterDestinationTitle)
const route = useRoute()
const isHotel = ['hotel-id', 'hotel-id-tab'].includes(route.name)

useHead({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,

  link: () => [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: t('favicon'),
    },
  ],

  script: [{
    innerHTML: () => localeMessages.organizationData,
    type: 'application/ld+json',
  }],

  meta: [
    {
      hid: 'author',
      name: 'author',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => t('siteTitleBrand'),
    },
  ],
})

const {
  pageBannerType: bannerType,
  pageBannerBackground: bannerBackground,

} = storeToRefs(rootStore)

const bannerImage = computed(() => (
  bannerBackground.value?.image?.url ||
  bannerBackground.value?.image?.src ||
  bannerBackground.value?.picture_cloudinary ||
  imagesConfig.fallbackHeroId
))

const updateDestinationActiveTab = (newActiveTab) => {
  charterDestinationStore.SET_CHARTER_DESTINATION_TAB(newActiveTab)
}
const updateHotelActiveTab = (newActiveTab) => {
  charterHotelStore.SET_CHARTER_HOTEL_TAB(newActiveTab)
}
</script>

<style lang="scss">
.charter-page-layout {
  .banner {
    max-height: 650px;
  }
  .layout-container,
  .banner-layout.container-content {
    max-width: $container-width !important;

    @media (max-width: $container-width) {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
}
</style>
